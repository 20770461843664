"use client"
import { signOut } from 'next-auth/react'
import { Button } from '../ui/button'
import { LogOut } from 'lucide-react'



const SignOutButton = () => {
  return (
    <div>
        <Button variant="secondary" onClick={()=>signOut()}>
            <LogOut />
         </Button>
    </div>
  )
}

export default SignOutButton