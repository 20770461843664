"use client"
 
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
 
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Textarea } from "../ui/textarea"
import { createProject } from "@/lib/actions"
import { useRouter } from "next/navigation"
import { useState } from "react"
import { LoaderCircle } from "lucide-react"

const formSchema = z.object({
    name: z.string().min(2, {
      message: "Project Name must be at least 2 characters.",
    }).max(100, {
      message: "Project Name can be max 100 characters."
    }),
    description: z.string().min(2, {
      message: "Project Description must be at least 2 characters.",
    }).max(400, {
      message: "Project Description can be max 400 characters."
    })
  })

  type CreateProjectFormProps = {
    onSuccess: () => void; 
  }

const CreateProjectForm = ({onSuccess}:CreateProjectFormProps) => {

    const router = useRouter();
    const [isLoading,setIsLoading] = useState(false)

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
          name: "",
          description:""
        },
      })
     
     async function onSubmit(values: z.infer<typeof formSchema>) {
        setIsLoading(true)
        const name = values.name;
        const description = values.description;
        const project = await createProject({name,description})
       
        router.push(`/${project.id}/detail`)
        setIsLoading(false)
        onSuccess()
      }


  return (
    <div>
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} 
            className="space-y-8"
            onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault(); 
                  form.handleSubmit(onSubmit)(); 
                }
              }}
            >
                <FormField
                disabled={isLoading}
                control={form.control}
                name="name"
                render={({ field }) => (
                    <FormItem>
                    <FormLabel>Project Name</FormLabel>
                    <FormControl>
                        <Input placeholder="project name..." {...field} />
                    </FormControl>
                    <FormDescription>
                        This is the name of your Product.
                    </FormDescription>
                    <FormMessage />
                    </FormItem>
                )}
                />
                <FormField
                disabled={isLoading}
                control={form.control}
                name="description"
                render={({ field }) => (
                    <FormItem>
                    <FormLabel>Project Description</FormLabel>
                    <FormControl>
                        <Textarea placeholder="project description..." {...field} />
                    </FormControl>
                    <FormDescription>
                        This is the short description of your Product.
                    </FormDescription>
                    <FormMessage />
                    </FormItem>
                )}
                />
                <Button disabled={isLoading} type="submit">
                    {
                        isLoading ? 
                        <div>
                            <LoaderCircle className="animate-spin" />
                        </div>:
                        <div>
                            Create
                        </div>
                    }
                </Button>
            </form>
        </Form>
    </div>
  )
}

export default CreateProjectForm