"use client"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "@/components/ui/dialog"
import CreateProjectForm from "./createproject-form"
import { Button } from "../ui/button"
import { useState } from "react"
import { Plus } from "lucide-react"



  

const CreateProjectButton = () => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


  return (
    <div>
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant='secondary' onClick={handleOpen}>
                    <Plus />
                </Button>
            </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                    <DialogTitle>Create a New Project</DialogTitle>
                    <DialogDescription>
                        Please fill the blanks
                    </DialogDescription>
                    </DialogHeader>
                    <CreateProjectForm onSuccess={handleClose} />
            </DialogContent>
        </Dialog>

    </div>
  )
}

export default CreateProjectButton