"use client"

import { signIn } from "next-auth/react"
import { Button } from "../ui/button"
import { FaGoogle } from "react-icons/fa";


const SignInButton = () => {
  return (
    <div>
        <Button className="flex flex-row items-center gap-1.5 bg-gradient-to-r from-[#050DEB] via-[#8906E6] to-[#FF00E2] text-white border-0 transform transition-all duration-300 hover:scale-105" onClick={()=>signIn("google")}>
            <FaGoogle />
            Sign in with Google
        </Button>
    </div>
  )
}

export default SignInButton